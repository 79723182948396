import React, {useEffect, useState} from "react";
import CustomCardsLenta from "../../components/CardsLenta/customCardsLenta";

import '../../styles/UpcomingDates.scss'

export default function UpcomingDatesTableItem({date, users}) {
    const [birthdayUsers, setBirthdayUsers] = useState([])
    const [corpDateUsers, setCorpDateUsers] = useState([])
    const daysOfWeek = ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"];

    const filterByCorpDate = () => {
        return users.filter(item => {
            try {
                const curDate = [...date.toLocaleDateString().matchAll(/(.*\..*)\..*/g)][0][1]
                const userDate = [...item.corp_date.matchAll(/(.*\..*)\..*/g)][0][1]
                return curDate === userDate
            } catch (e) {
                return false
            }
        })
    }

    const filterByBirthDate = () => {
        return users.filter(item => {
            try {
                const curDate = [...date.toLocaleDateString().matchAll(/(.*\..*)\..*/g)][0][1]
                const userDate = [...item.birth_date.matchAll(/(.*\..*)\..*/g)][0][1]
                return curDate === userDate
            } catch (e) {
                return false
            }
        })
    }

    useEffect(() => {
        setCorpDateUsers(filterByCorpDate())
        setBirthdayUsers(filterByBirthDate())
    }, [users]);

    return (
        <div className={'upcomingDatesTableItem'}>
            <div className={'dateLabel'}>
                <span className={'day'}>{daysOfWeek[date.getDay()]}</span> {date.toLocaleDateString()}
            </div>

            <div className={'main'}>
                {(birthdayUsers.length || corpDateUsers.length) ?
                    <CustomCardsLenta birthdayUsers={birthdayUsers} corpDateUsers={corpDateUsers}/>
                    :
                    <div className={'notEventsMessage'}>Нет событий</div>
                }
            </div>
        </div>
    )
}